import { getStoreCreditExpiredSoon } from '@modules/storecredit/services/graphql';
import AlertView from './view';

const StoreCreditAlert = (props) => {
    const {
        t, rowsPerPage = 10, ...other
    } = props;
    const [page] = React.useState(0);
    const [perPage] = React.useState(rowsPerPage);

    let storeCreditItems = [];
    const { data } = getStoreCreditExpiredSoon(
        {
            pageSizeStoreCredit: perPage,
            currentPageStoreCredit: page + 1,
        },
    );
    if (data) {
        storeCreditItems = data?.customer?.store_credit?.expired_soon_point?.items || [];
    }
    return (
        <AlertView t={t} items={storeCreditItems} {...other} />
    );
};

export default StoreCreditAlert;
