import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY } from '@theme_color';
import { Centering } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    caraousel: {
        height: '100%',
        width: 'calc(100% - 26px)',
        paddingRight: '10px',
        paddingLeft: '10px',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100vw',
        },
    },
    arrow: {
        fontSize: '1rem',
        color: '#2870B8',
        backgroundColor: '#fff',
        position: 'absolute',
        ...Centering,
        padding: 10,
        borderRadius: 5,
        textAlign: 'center',
        top: '0px',
        width: 18,
        height: 18,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: PRIMARY,
            color: WHITE,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    rightArrow: {
        right: 20,
    },
    alertCoupon: {
        backgroundColor: '#fff',
        color: '#2870B8',
        width: '100%',
        textAlign: 'center',
        padding: '8px',
        fontSize: '12px',
        fontFamily: 'Montserrat !important',
        alignItems: 'center',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            fontSize: '14px',
        },
        display: 'flex',
        flexDirection: 'row',
    },
    slickTrack: {
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
    },
    buttonClose: {
        float: 'left',
        backgroundColor: '#2870B8',
        color: '#fff',
        borderRadius: '99px',
        width: '18px',
        height: '18px',
        lineHeight: '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        fontSize: '14px',
        zIndex: 10,
        '&:hover': {
            backgroundColor: PRIMARY,
            color: WHITE,
        },
    },
}));

export default useStyles;
