import dayjs from 'dayjs';

const formatDate = (date = new Date(), format = 'D MMMM YYYY, HH:mm:ss') => dayjs(date).format(format);

export const getMonth = (date = new Date()) => {
    const month = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];

    return month[date.getMonth()];
};

export const getYear = (date = new Date()) => dayjs(date).format('YYYY');

export default formatDate;
