import Badge from '@material-ui/core/Badge';

const WithoutLink = ({ cartData = 0 }) => (
    <Badge showZero badgeContent={cartData || 0}>
        {/* <BagIcon height={24} /> */}
        <img alt="bag" width={32} height={32} src="/assets/img/pdp/bag.svg" />
    </Badge>
);

export default WithoutLink;
