/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import { formatPrice } from '@helper_currency';
import formatDate from '@helper_date';
import useStyles from './style';

const AlertView = (props) => {
    const {
        t, items,
    } = props;
    const sliderRef = React.useRef(null);
    const styles = useStyles();
    const [show, setShow] = React.useState(true);

    const renderMessage = (item) => `${t('common:StoreCreditAlert:youHave')} ${formatPrice(item.store_credit_balance.value, 'IDR')} ${t('common:StoreCreditAlert:willExp')} ${formatDate(item.expired_date)}`;

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        rows: 1,
        slidesPerRow: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        className: 'slider',
        centerMode: true,
        centerPadding: '0px',
    };

    return (
        items && items.length > 0 && (
            <>
                <div className={classNames(styles.alertCoupon, !show && 'hide')}>
                    <div className={classNames(styles.caraousel, !show && 'hide')}>
                        <Slider ref={sliderRef} {...settings}>
                            {items.map((item, idx) => (
                                item.store_credit_adjustment.value > 0 && item.expired_date !== '' && (
                                    <span className={classNames(styles.alertText)} key={idx}>
                                        {renderMessage(item)}
                                    </span>
                                )
                            ))}
                        </Slider>
                    </div>
                    <div>
                        <span className={styles.buttonClose} onClick={() => setShow(false)}>
                            x
                        </span>
                    </div>
                </div>
                <style jsx>
                    {`
                            .hide {
                                height: 0;
                            }
                        `}
                </style>
            </>
        )
    );
};

export default AlertView;
